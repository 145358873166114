import { Component } from "react";

let GoogleMapsLoader;

/*function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}*/

class GoogleContainer extends Component {
  gMapsConfig = {
    //googleMapsClient: "gme-thedemocraticalliance",
    //googleMapsChannel: "selfcanvass"
    googleMapsAPIKey: "AIzaSyCX2UVtZISuBcNRXFio0z_PzSAaub9G-kQ"
    //googleMapsAPIKeys: ['AIzaSyA8WtRI8lNIu8olsXSf91FQBry4fSEhZX8']
  };

  constructor(props) {
    super(props);
    this.state = { google: null };
  }

  componentDidMount() {
    GoogleMapsLoader = require("google-maps");
    // GoogleMapsLoader.CLIENT = this.gMapsConfig.googleMapsClient;
    // GoogleMapsLoader.CHANNEL = this.gMapsConfig.googleMapsChannel;
    GoogleMapsLoader.KEY = this.gMapsConfig.googleMapsAPIKey;

    GoogleMapsLoader.VERSION = "quarterly";

    GoogleMapsLoader.LIBRARIES = ["places"];
    GoogleMapsLoader.load(google => {
      this.setState({
        google
      });
    });
  }

  componentWillUnmount() {}

  render() {
    return this.props.render(this.state.google);
  }
}

export default GoogleContainer;
