import React from "react";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";
import "./App.css";

import LandingContainer from "./modules/Landing/LandingContainer";
import ConfirmContainer from "./modules/Canvass/ConfirmContainer";
import { Header, Footer } from "./components";

const App = () => (
  <React.Fragment>
    <Header key={"page-header"} />
    <Switch>
      <Route exact path="/" component={LandingContainer} />
      <Route exact path="/voter/:id" component={ConfirmContainer} />
      <Route path="/:referralCode" component={LandingContainer} />
    </Switch>
    <Footer />
  </React.Fragment>
);

export default App;
