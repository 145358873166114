import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByPlaceId
} from "react-places-autocomplete";
import { Copy, Label } from "@website2018/da-dobsonville";

import {
  formatLocationData,
  iterateAdreessAndRetrieve,
  formatAddressObject
} from "../../common/utils";

const renderSuggestion = ({ formattedSuggestion }) => (
  <div className="Demo__suggestion-item">
    <strong>{formattedSuggestion.mainText}</strong>{" "}
    <small className="text-muted">{formattedSuggestion.secondaryText}</small>
  </div>
);

const renderFooter = () => (
  <div className="Demo__dropdown-footer">
    <div>
      <img
        alt="google logo"
        src={require("../../common/images/powered_by_google_default.png")}
        className="Demo__dropdown-footer-image"
      />
    </div>
  </div>
);

const cssClasses = {
  root: "places-group",
  input: "form-control",
  autocompleteContainer: "Demo__autocomplete-container"
};

const shouldFetchSuggestions = ({ value }) => value.length > 4;

const onError = (status, clearSuggestions) => {
  console.log(
    "Error happened while fetching suggestions from Google Maps API",
    status
  );
  clearSuggestions();
};

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      geocodeError: null,
      loading: false
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.sessionToken = new window.google.maps.places.AutocompleteSessionToken();
  }

  handleSelect(address, placeId) {
    this.setState({
      address,
      loading: true
    });

    geocodeByPlaceId(placeId)
      .then(results => {
        if (results && results.length) {
          return results[0];
        }

        return [];
      })
      .then(enteredLocation => {
        this.setState({
          loading: false
        });

        const addressObject = iterateAdreessAndRetrieve(enteredLocation);
        const formattedAddressObject = formatAddressObject(addressObject);
        const formattedAddressStringWithCountry =
          enteredLocation.formatted_address;
        const formattedAddressStringWithoutCountry = formattedAddressStringWithCountry.replace(
          /,[^,]+$/,
          ""
        );
        const formattedAddressString = formattedAddressStringWithCountry;
        const placeId = enteredLocation.place_id;
        const lat = enteredLocation.geometry.location.lat();
        const lng = enteredLocation.geometry.location.lng();
        const geoLocation = { lat, lng };

        const formattedLocationData = formatLocationData(
          placeId,
          formattedAddressObject,
          formattedAddressString,
          formattedAddressStringWithoutCountry,
          geoLocation
        );

        this.setState({
          formattedLocationData,
          geoLocation
        });
        this.props.onSelect(formattedLocationData, geoLocation);
      })
      .catch(error => {
        console.log("Geocode Error", error);
        this.setState({
          geocodeError: error,
          loading: false
        });
      });
  }

  handleChange(address, arg) {
    this.props.onChange(address);
    this.setState({
      address,
      geocodeResults: null
    });
  }

  renderGeocodeFailure(err) {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Error!</strong> {err}
      </div>
    );
  }

  render() {
    const inputProps = {
      type: "text",
      value: this.props.input,
      onChange: this.handleChange,
      autoFocus: false,
      placeholder:
        this.props.placeholder || "Enter your residential street address",
      name: this.props.id,
      id: this.props.id
    };

    return (
      <div className="form-group">
        <Label>{this.props.label}</Label>
        <PlacesAutocomplete
          renderSuggestion={renderSuggestion}
          renderFooter={renderFooter}
          inputProps={inputProps}
          classNames={cssClasses}
          onSelect={this.handleSelect}
          onEnterKeyDown={this.handleSelect}
          onError={onError}
          shouldFetchSuggestions={shouldFetchSuggestions}
          options={{
            componentRestrictions: { country: "za" },
            sessionToken: this.sessionToken
          }}
        />
        {this.props.description ? (
          <Copy size={"small"}>{this.props.description}</Copy>
        ) : null}
        {this.state.geocodeError ? (
          <Copy size={"small"} color={"red"}>
            {this.state.geocodeError}
          </Copy>
        ) : null}
        {this.props.addressError ? (
          <Copy size={"small"} color={"red"}>
            {this.props.addressError}
          </Copy>
        ) : null}
        {this.props.showInputs ? <p>das</p> : null}
      </div>
    );
  }
}

export default SearchBar;
