const language = {
  regAddressHeading: "Registration Address",
  regAddressCopy:
    "ALERT! Your address is incomplete on the Voters Roll. The Constitutional Court ruled that the Electoral Commission must collect addresses for all voters. Please <a href='https://www.elections.org.za/MyIEC/Account/Register' target='_blank'>change your registration online</a> or at your voting station during the next voter registration weekend.",
  vdNotFound: "Your voting district could not be found",
  // registerAt: "You are not registered in the voting district where you live.  Please <a href='https://www.elections.org.za/MyIEC/Account/Register' target='_blank'>change your registration online</a> or go to your voting station at the next voter registration weekend: ",
  registerAt: `<br/><p style="color:#000000;font-weight:bold;font-size:120%">You are registered to vote.</p> <p style="color:#bb0000;font-weight:bold;">However, you don't live in the voting district where you are registered. To vote in an election, you will now need to update your registration with the IEC.</p>
    <p>To update your registration, next we will direct you to the IEC’s Voter Registration portal. On that portal, you will need to provide your personal details as they appear in your ID, as well as your address, and you’ll need to confirm these as accurate, and upload a picture of your ID document.</p>
    <p>Please <a href="https://www.da.org.za/a-guide-to-the-iecs-online-voter-registration-process">click here</a> for some helpful documents and guides to help you through IEC Process. You can also request help from the DA on email: <a href="mailto:registration@da.org.za">registration@da.org.za</a> and we will get back to you as soon as possible.</p>`,
  registered: "You are registered in the right place.",
  notRegistered: `<p style="color:#000000;font-weight:bold;font-size:120%"><span style="color:#bb0000">You are not registered to vote.</span></p>
    <p>To register, next we will direct you to the IEC’s Voter Registration portal. On that portal, you will need to provide your personal details as they appear in your ID, as well as your address, and you’ll need to confirm these as accurate, and upload a picture of your ID document.</p>
    <p>Please <a href="https://www.da.org.za/a-guide-to-the-iecs-online-voter-registration-process">click here</a> for some helpful documents and guides to help you through IEC Process. You can also request help from the DA on email: <a href="mailto:registration@da.org.za">registration@da.org.za</a> and we will get back to you as soon as possible.</p>`,
  invalidEmail: "Please provide a valid email address.",
  invalidPhone: "Please provide a valid cellphone number.",
  invalidReminder:
    "Please provide at least a cellphone number or email address to be reminded.",
  reminderHeading: "Remind me please!",
  reminderCopy:
    "Please provide your cellphone number and/or email address so that we can send you reminders.",
  reminderSuccess: "Reminder successfully set.",
  reminderButton: "SEND REMINDER",
  voterId: "My ID number",
  regStatus: "Your registration status:",
  myVotingStation:
    "Your voting station at which you need to be registered to vote, according to the home address you have entered:",
  share: "Share",
  shareCopy:
    "Please help by sharing this service so your friends and family can check their registration and ensure they are able to vote where they want to on election day.",
  regOnlineHeading: "Can I change my address online?",
  regOnlineCopy:
    "If you are registered, you can supply or change your address on the IEC website.",
  regOnlineButton: "CHANGE ADDRESS @ IEC",
  mapHeading: "Check your voter registration status",
  mapCopy:
    "You may not be able to vote where you live if your address is missing or incorrect at the Electoral Commission!",
  errors: {
    streetNumber:
      "To check your registration details, we need to know exactly where you live. Please give a more specific location, by entering a full street address or dragging the pin to your address on the map.",
    street:
      "To check your registration details, we need to know exactly where you live. Please give a more specific location by entering a full street address.",
    idnumber: "Your ID Number is not correct. Please check it carefully.",
    city: "Invalid location",
    state: "Invalid location",
    country: "Invalid location",
    required: "Required Field"
  }
};

export default language;
