import React from "react";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";

class FormContainer extends React.Component {
  state = {
    errors: [],
    sent: false,
    error: null,
    loading: false
  };

  render() {
    const { loading, form, error } = this.props;

    if (loading) return null;
    if (error) return <p>An unexpected error has occured</p>;

    return this.props.render(form);
  }
}

FormContainer.defaultProps = {
  formId: null
};

const FORM_QUERY = gql`
  fragment FormParts on Fields {
    label
    placeholder
    description
    query
    id
    next
    event {
      type
      go
      eventName
    }
    type
    className
    required
    validate
    buttonLabel
    options {
      label
      value
    }
    defaultValue
    conditional
    stateManage
    conditionalLogic {
      actionType
      logic {
        field
        match
        value
      }
    }
  }
  query getForm($formId: String!) {
    form(id: $formId) {
      id
      title
      description
      submission
      buttonLabel
      confirmation {
        type
        label
        to
      }
      stateManage
      fields {
        ...FormParts
        fields {
          ...FormParts
          fields {
            ...FormParts
          }
        }
      }
    }
  }
`;

const withForm = graphql(FORM_QUERY, {
  options: props => {
    return {
      variables: {
        formId: props.formId
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

export default compose(withForm)(FormContainer);
