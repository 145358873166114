import React from "react";
import { hydrate } from "react-dom";

import { BrowserRouter } from "react-router-dom";

import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

import { StyleSheet } from "aphrodite";
import App from "./App";

require("es6-promise").polyfill();
require("isomorphic-fetch");
require("babel-polyfill");

StyleSheet.rehydrate(window.__APHRODITE_STATE__);

const client = new ApolloClient({
  link: createHttpLink({ uri: process.env.RAZZLE_GQL_HOST || "/graphql" }),
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__)
});

const WrappedApp = (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);

hydrate(WrappedApp, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
