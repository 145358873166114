import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  formatLocationData,
  iterateAdreessAndRetrieve,
  formatAddressObject
} from "../../common/utils";

class GoogleMapContainer extends Component {
  static propTypes = {
    onPlaceChanged: PropTypes.func
  };

  gMapsConfig = {
    googleStaticMapsAPIKey: "AIzaSyCX2UVtZISuBcNRXFio0z_PzSAaub9G-kQ",
    zoomLevel: 13,
    scaleLevel: 1,
    imageSize: "640x640",
    imageFormat: "jpg",
    markerColor: "blue"
  };

  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.google = null;
    this.map = null;
    this.marker = null;
    this.vs = null;
  }

  componentDidMount() {
    const { google } = this.props;

    this.google = google;

    this.marker = new google.maps.Marker({
      draggable: true
    });

    google.maps.event.addListener(this.marker, "dragend", event => {
      this.setPoint(event.latLng);
    });

    this.loadMap(google);

    if (this.props.loadVoter) {
      this.loadVoter();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentLocation !== this.props.currentLocation) {
      this.recenterMap();
    }

    if (prevProps.voter !== this.props.voter) {
      this.loadVoter();
    }
  }

  loadVoter() {
    const { livingVD } = this.props;
    const maps = this.google.maps;
    const google = this.google;

    this.map.data.forEach(f => {
      this.map.data.remove(f);
      this.vs.setMap(null);
    });

    var feature = this.map.data.addGeoJson(livingVD.geometry)[0];
    this.map.fitBounds(this.multipoly_bounds(feature.getGeometry()));

    if (livingVD.votingstations.length) {
      const { vs_latitude, vs_longitude } = livingVD.votingstations[0];
      const icon = {
        url:
          "https://cdn.da.org.za/wp-content/uploads/2018/02/14234316/iec-1.png", // url
        scaledSize: new google.maps.Size(30, 30) // scaled size
      };
      this.vs = new google.maps.Marker({ icon });

      let center = new maps.LatLng(vs_latitude, vs_longitude);

      this.vs.setPosition(center);
      this.vs.setMap(this.map);
    }
  }

  multipoly_bounds(geometry) {
    var bds = new this.google.maps.LatLngBounds();

    geometry.getArray().forEach(poly => {
      var or = poly.getAt(0);
      or.getArray().forEach(ll => {
        bds.extend(ll);
      });
    });
    return bds;
  }

  loadMap(google) {
    const maps = google.maps;

    let zoom = this.gMapsConfig.zoomLevel;
    const { lat, lng } = this.props.currentLocation;
    const center = new maps.LatLng(lat, lng);
    const mapConfig = Object.assign(
      {},
      {
        center: center,
        zoom: zoom
      }
    );

    this.map = new maps.Map(this.refs.map, mapConfig);

    this.map.fitBounds(
      new google.maps.LatLngBounds(
        new google.maps.LatLng(-34.833, 16.45),
        new google.maps.LatLng(-22.128, 32.892)
      )
    );

    google.maps.event.addListener(this.map, "click", point => {
      this.setPoint(point.latLng);
    });

    //this.geolocate();
  }

  recenterMap() {
    const map = this.map;
    const marker = this.marker;
    const curr = this.props.currentLocation;

    const google = this.google;
    const maps = google.maps;

    if (map) {
      let center = new maps.LatLng(curr.lat, curr.lng);
      map.setCenter(center);

      const zoom = map.getZoom();

      if (zoom < 13) map.setZoom(15);

      marker.setPosition(center);
      marker.setMap(map);
    }
  }

  setPoint(latLng) {
    var geocoder = new this.google.maps.Geocoder();
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (results && results.length) {
        const enteredLocation = results[0];

        const addressObject = iterateAdreessAndRetrieve(enteredLocation);
        const formattedAddressObject = formatAddressObject(addressObject);

        const formattedAddressStringWithCountry =
          enteredLocation.formatted_address;
        const formattedAddressStringWithoutCountry = formattedAddressStringWithCountry.replace(
          /,[^,]+$/,
          ""
        );
        const formattedAddressString = formattedAddressStringWithCountry;
        const placeId = enteredLocation.place_id;
        const lat = enteredLocation.geometry.location.lat();
        const lng = enteredLocation.geometry.location.lng();
        const geoLocation = { lat, lng };
        const staticMapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${
          this.gMapsConfig.zoomLevel
        }&format=${this.gMapsConfig.imageFormat}&markers=color:${
          this.gMapsConfig.markerColor
        }%7Clabel:S%7C${lat},${lng}&key=${
          this.gMapsConfig.googleStaticMapsAPIKey
        }`; // removed &size=${imageSize}&scale=${scaleLevel} so can append it dynamically when showing the image
        const formattedLocationData = formatLocationData(
          placeId,
          formattedAddressObject,
          formattedAddressString,
          formattedAddressStringWithoutCountry,
          geoLocation,
          staticMapURL
        );
        this.props.onSetLocation({
          lat: latLng.lat(),
          lng: latLng.lng(),
          address: formattedLocationData
        });
      }
    });
  }

  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.map.setCenter(geolocation);
        this.map.setZoom(15);
      });
    }
  }

  render() {
    return <div className={this.props.mapStyle} ref="map" />;
  }
}

GoogleMapContainer.defaultProps = {
  mapStyle: "map",
  voter: null,
  currentLocation: {
    lat: -34.833,
    lng: 16.45
  }
};

export default GoogleMapContainer;
