import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import { Heading, Icon, variables } from "@website2018/da-dobsonville";

class ListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleItem = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { colors, spacing } = variables;
    const styles = StyleSheet.create({
      item: {
        margin: 0,
        padding: 0,
        listStyle: "none"
      },
      header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid",
        borderBottomColor: colors.borderBase,
        paddingTop: spacing.space2,
        paddingBottom: spacing.space2
      }
    });

    if (this.props.link) {
      return (
        <div className={css(styles.header)} onClick={() => this.toggleItem()}>
          <Heading
            level={5}
            color={"blue"}
            weight={"medium"}
            size={"medium"}
            override={{ fontSize: "18px" }}
          >
            {this.props.title}
          </Heading>
        </div>
      );
    }

    return (
      <li className={css(styles.item)}>
        <div className={css(styles.header)} onClick={() => this.toggleItem()}>
          <Heading
            level={5}
            color={"blue"}
            weight={"medium"}
            size={"medium"}
            override={{ fontSize: "18px" }}
          >
            {this.props.title}
          </Heading>

          {this.props.children && this.props.collapsed && (
            <Icon
              name={this.state.open ? "angle-up" : "angle-down"}
              color={colors.textBlue}
            />
          )}
        </div>

        {this.props.collapsed && this.state.open && this.props.children}
        {!this.props.collapsed ? this.props.children : null}
      </li>
    );
  }
}

export default ListItem;
