import React from "react";
import { Copy, Label } from "@website2018/da-dobsonville";

const SelectField = ({
  id,
  inputClass,
  options,
  className,
  error,
  label,
  type,
  placeholder,
  description,
  inputOptions = {}
}) => {
  return (
    <div className={`${inputClass ? inputClass : className} form-group`}>
      <Label>{label}</Label>
      <select
        name={id}
        placeholder={placeholder}
        className="form-control"
        {...inputOptions}
      >
        <option />
        {options.map((option, index) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <Copy mb="0" color="red" size="small">
          {error}
        </Copy>
      )}
      {description ? (
        <small>
          <em>{description}</em>
        </small>
      ) : null}
    </div>
  );
};

SelectField.defaultProps = {
  className: "col-md-6"
};

export default SelectField;
