import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { Loading } from "@website2018/da-dobsonville";

class QueryContainer extends Component {
  render() {
    const { query } = this.props;

    if (!Queries[query]) return null;
    const dataQuery = Queries[query];

    return (
      <Query query={dataQuery.query}>
        {({ data, loading, error }) => {
          if (loading) return <Loading />;
          if (error) return <p>{error}</p>;
          return this.props.render(data[dataQuery.name]);
        }}
      </Query>
    );
  }
}

const Queries = {
  GET_AFFILIATIONS_MAIN: {
    name: "getAffiliations",
    query: gql`
      query getAffiliations {
        getAffiliations(type: main) {
          id
          value: code
          label: name
        }
      }
    `
  },
  GET_AFFILIATIONS_OTHER: {
    name: "getAffiliations",
    query: gql`
      query getAffiliations {
        getAffiliations(type: other) {
          id
          value: code
          label: name
        }
      }
    `
  }
};

export default QueryContainer;
