export const validateIdNumber = idNumber => {
  let correct = true;

  if (idNumber.length !== 13) {
    return false;
  }

  var tempTotal = 0;
  var checkSum = 0;
  var multiplier = 1;
  for (var i = 0; i < 13; ++i) {
    tempTotal = parseInt(idNumber.charAt(i), 10) * multiplier;
    if (tempTotal > 9) {
      tempTotal =
        parseInt(tempTotal.toString().charAt(0), 10) +
        parseInt(tempTotal.toString().charAt(1), 10);
    }
    checkSum = checkSum + tempTotal;
    multiplier = multiplier % 2 === 0 ? 1 : 2;
  }

  if (checkSum % 10 !== 0) {
    correct = false;
  }

  return correct;
};

export const getShareProperties = (type, page) => {
  const {
    seo: { domain, campaign = "SC", shareText = null }
  } = page;
  const url = domain || window.location.href;

  if (type === "fb") {
    return {
      url: `${url}?ref=${campaign.toUpperCase()}-${type.toUpperCase()}`
    };
  }

  if (type === "tw" || type === "wa") {
    return {
      url: `${url}?ref=${campaign.toUpperCase()}-${type.toUpperCase()}`,
      title: shareText || page.seo["opengraph-title"] || page.title.rendered
    };
  }

  if (type === "email") {
    return {
      url: `${url}?ref=${campaign.toUpperCase()}-${type.toUpperCase()}`,
      subject: page.seo["opengraph-title"] || page.title.rendered,
      body: `${shareText} ${url}?ref=${campaign.toUpperCase()}-${type.toUpperCase()}`
    };
  }
};

export const formatLocationData = (
  placeId,
  formattedAddressObject,
  formattedAddressString,
  formattedAddressStringWithoutCountry,
  geoLocation,
  staticMapURL
) => {
  return {
    placeId,
    formattedAddressObject,
    formattedAddressString,
    formattedAddressStringWithoutCountry,
    geoLocation
  };
};

export const iterateAdreessAndRetrieve = enteredLocation => {
  // Get each component of the address from the enteredLocation details
  // and fill the corresponding field on the form.
  let addressObject = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    sublocality_level_1: "short_name",
    sublocality: "short_name",
    country: "short_name",
    postal_code: "short_name"
  };

  let returnObject = {};

  for (let i = 0; i < enteredLocation.address_components.length; i++) {
    enteredLocation.address_components[i].types.forEach(addressType => {
      if (addressObject[addressType]) {
        let val =
          enteredLocation.address_components[i][addressObject[addressType]];
        returnObject[addressType] = val;
      }
    });
  }
  return returnObject;
};

export const formatAddressObject = addressObject => {
  const formattedAddressObject = {};

  formattedAddressObject["streetNumber"] = addressObject["street_number"];
  formattedAddressObject["street"] = addressObject["route"];
  formattedAddressObject["suburb"] = addressObject["sublocality_level_1"]
    ? addressObject["sublocality_level_1"]
    : addressObject["sublocality"];
  formattedAddressObject["city"] = addressObject["locality"];
  formattedAddressObject["state"] =
    addressObject["administrative_area_level_1"];
  formattedAddressObject["country"] = addressObject["country"];
  formattedAddressObject["zipCode"] = addressObject["postal_code"];
  return formattedAddressObject;
};

export const mapper = (data, map) => {
  const newData = {};

  const getNestedObject = (nestedObj, pathArr) => {
    return pathArr.reduce(
      (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
      nestedObj
    );
  };

  Object.keys(map).forEach(key => {
    const property = map[key];
    const obj = getNestedObject(data, property);
    if (obj) {
      newData[key] = obj;
    }
  });

  return newData;
};
