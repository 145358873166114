import React, { Fragment } from "react";
import { Aside, AsideOverlay } from "@website2018/da-dobsonville";

const Asides = props => {
  return [
    <Fragment>
      {props.asides.map(a => (
        <AsideSingle
          key={a.id}
          type={a.aside_options.type}
          imageUrl={a.aside_options.image.url}
          {...a}
        />
      ))}
    </Fragment>
  ];
};

Asides.defaultProps = {
  asides: [],
  type: "Aside"
};
export default Asides;

const AsideSingle = props => {
  const { type } = props;

  switch (type) {
    case "Aside":
      return (
        <Aside
          key={props.id}
          imageUrl={props.imageUrl}
          meta={props.aside_options.aside.meta}
          title={props.aside_options.headline}
          paragraph={props.content.rendered}
          url={props.aside_options.aside.url}
          cta={props.aside_options.aside.button_label}
          vertical
        />
      );
    case "AsideOverlay":
      return (
        <AsideOverlay
          key={props.id}
          color={props.aside_options.asideoverlay.color}
          imageUrl={props.imageUrl}
          title={props.aside_options.headline}
          paragraph={props.content.rendered}
          url={props.aside_options.asideoverlay.url}
          cta={props.aside_options.asideoverlay.button_label}
        />
      );
    default:
      return null;
  }
};
