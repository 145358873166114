import React from "react";
import { StyleSheet, css } from "aphrodite";
import { variables, FooterLegal } from "@website2018/da-dobsonville";

const Footer = props => {
  const { colors, spacing } = variables;
  const styles = StyleSheet.create({
    menus: {
      paddingTop: spacing.space6,
      paddingBottom: spacing.space3,
      backgroundColor: colors.bgBlueLight,
      display: "inline-block",
      width: "100%"
    },
    legal: {
      backgroundColor: colors.bgBlue,
      display: "inline-block",
      width: "100%"
    }
  });

  return (
    <footer className={css(styles.footer)}>
      <div className={css(styles.legal)}>
        <div className="container">
          <FooterLegal />
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  color: "Copy"
};

export default Footer;
