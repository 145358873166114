import React from "react";
import { Heading, Copy } from "@website2018/da-dobsonville";
import FormBuilderContainer from "../../containers/Data/FormBuilderContainer";
import FormContainer from "../../containers/Data/FormContainer";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router-dom";

export class CanvassContainer extends React.Component {
  successContent(page) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "formSubmission",
      category: "FormSelfCanvas",
      action: "Success",
      label: "self_canvas_form"
    });
    window.lintrk("track", { conversion_id: 15679521 });
    return (
      <React.Fragment>
        <Heading level={6} mt="small" mb="small" color={"blue"}>
          Thank you for your submission. Please check your cellphone and/or
          email for your voter information link.
        </Heading>
      </React.Fragment>
    );
  }
  render() {
    const { referralCode, path } = this.props;
    return (
      <FormContainer
        formId="canvass"
        render={form => (
          <Sections
            form={form}
            referralCode={referralCode}
            path={path}
            render={(form, current, next) =>
              !form ? (
                this.successContent(this.props.page)
              ) : (
                <FormBuilderContainer
                  buttonColor={"green"}
                  page={this.props.page}
                  form={form}
                  onSubmit={next}
                />
              )
            }
          />
        )}
      />
    );
  }
}

class Sections extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      current: this.props.form.fields[0],
      success: false,
      form: {
        referralCode: props.referralCode,
        path: props.path
      }
    };
  }

  next = async values => {
    const { event } = this.state.current;

    if (event && event.type === "next") {
      const nextSection = this.props.form.fields.find(
        f => f.id === event.go[0]
      );
      this.setState(state => ({
        current: nextSection,
        form: {
          ...state.form,
          ...values
        }
      }));
    } else if ((event && event.type === "event") || event.type === "submit") {
      let nextSection = this.props.form.fields.find(f => f.id === event.go[0]);

      await this.props.submitFormEvent({
        variables: {
          event: event.eventName,
          host: window.location.host,
          form: {
            ...this.state.form,
            ...values
          }
        }
      });

      this.setState(state => ({
        form: {
          ...state.form,
          ...values
        },
        current: nextSection
      }));
    }
  };

  render() {
    const { current } = this.state;
    const form = current;
    return this.props.render(form, current, this.next);
  }
}

const ADD_EVENT_MUTATION = gql`
  mutation submitFormEvent($event: String!, $host: String, $form: JSON!) {
    submitFormEvent(event: $event, host: $host, form: $form)
  }
`;

Sections = graphql(ADD_EVENT_MUTATION, { name: "submitFormEvent" })(
  withRouter(Sections)
);
