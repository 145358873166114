import React, { Component } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Loading } from "@website2018/da-dobsonville";

class PageContainer extends Component {
  render() {
    const { loading, page, error } = this.props;

    if (loading) return <Loading />;
    if (error) return <h1>ERROR</h1>;

    return this.props.render(page);
  }
}

const PAGE_QUERY = gql`
  query getTemplate($slug: String) {
    page(slug: $slug) {
      id
      slug
      title {
        rendered
      }
      content {
        rendered
      }
      excerpt {
        rendered
      }
      hero {
        headline
        cta_type
        cta_group {
          title
          link_label
          link_url
        }
        background_image {
          url
        }
      }
      faqTitle
      faq {
        question
        answer
      }
      map {
        map_title
        map_description
      }
      asides {
        id
        title {
          rendered
        }
        content {
          rendered
        }
        aside_options
      }
      featured_media {
        id
        source_url
        title {
          rendered
        }
      }
      seo
    }
  }
`;

const withPage = graphql(PAGE_QUERY, {
  options: props => {
    return {
      variables: {
        slug: process.env.RAZZLE_PAGE_SLUG || "sc"
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

export default withPage(PageContainer);
