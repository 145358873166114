import React, { Component } from "react";
import { Alert, Copy, Heading, ButtonTest } from "@website2018/da-dobsonville";
import serializeForm from "form-serialize";
import {
  TextField,
  SelectField,
  RadioField,
  TextAreaField,
  MultiInput
} from "../../components/Form";
import * as R from "ramda";
import { validateIdNumber, mapper } from "../../common/utils";
import Spacing from "../../components/Spacing/Spacing";
import SearchBar from "../Map/SearchBar";
import GoogleContainer from "../Map/Google";
import language from "../../common/language";
import QueryContainer from "./QueryContainer";

class FormBuilderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errors: [],
      sent: false,
      loading: false,
      form: {},
      input: ""
    };
  }

  validate = values => {
    const errors = {};

    const {
      form: { fields, type, validate },
      values: stateValues
    } = this.props;

    if (type === "group") {
      if (validate === "emailOrCellphone") {
        fields.forEach(field => {
          let hasContact = false;

          if (values.email || values.cellphone) {
            hasContact = true;
          }

          if (!hasContact) {
            errors.email = "Email or Cellphone number required.";
            errors.cellphone = "Email or Cellphone number required.";
          }
        });
      }
    }

    fields.forEach(field => {
      if (field.type === "file" && field.required && this.showField(field)) {
        const getFile = R.path(["uploads", field.id]);
        if (!getFile(stateValues)) {
          errors[field.id] = "Required Field";
        }
      } else if (field.required && !values[field.id] && this.showField(field)) {
        errors[field.id] = "Required Field";
      } else if (
        field.validate === "phone" &&
        !/^0(6[0-9]|7[0-46-9]|8[1-4])\d{7}$/i.test(values[field.id]) &&
        this.showField(field)
      ) {
        errors[field.id] = "Invalid phone number";
      } else if (
        field.validate === "email" &&
        values[field.id] &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values[field.id]) &&
        this.showField(field)
      ) {
        errors[field.id] = "Invalid email address";
      } else if (
        field.validate === "idnumber" &&
        this.showField(field) &&
        !validateIdNumber(values[field.id])
      ) {
        errors[field.id] = "Invalid ID Number";
      } else if (field.type === "location" && this.showField(field)) {
        if (!values[field.id] || !values[field.id].street) {
          errors[field.id] = language.errors.street;
        }
      }
    });

    return errors;
  };

  componentDidUpdate() {}

  formatValues = (values, state) => {
    let formatValues = {
      ...values
    };
    const {
      form: { fields }
    } = this.props;

    fields.forEach(field => {
      if (field.validate === "phone" && values[field.id]) {
        formatValues[field.id] = formatValues[field.id].replace(/[^0-9]+/g, "");
      } else if (field.validate === "email" && values[field.id]) {
        formatValues[field.id] = formatValues[field.id].replace(/\s/g, "");
      } else if (field.validate === "idnumber" && values[field.id]) {
        formatValues[field.id] = formatValues[field.id].replace(/[^0-9]+/g, "");
      } else if (field.type === "location") {
        if (state.address) {
          const {
            address: { formattedAddressObject = null },
            currentLocation = null
          } = state;
          formatValues[field.id] = {
            ...formattedAddressObject,
            ...currentLocation
          };
        } else {
          formatValues[field.id] = {};
        }
      }
    });

    return formatValues;
  };

  getErrors(id) {
    const { errors } = this.state;

    const index = Object.keys(errors).indexOf(id);
    if (index > -1) {
      return errors[Object.keys(errors)[index]];
    }

    return null;
  }

  getGroupField(group) {
    return group.fields.map(field => {
      const shouldShow = this.getFieldConditional(field);

      if (shouldShow) return this.getField(field);

      return null;
    });
  }

  getField(field) {
    const {
      form,
      inputClass = null,
      inputOptions = null,
      readOnly
    } = this.props;

    let fieldOptions;

    if (inputOptions) {
      fieldOptions = {
        ...field,
        inputOptions: {
          onChange: inputOptions.onChange,
          readOnly: readOnly[field.id]
        }
      };
    } else if (form.stateManage) {
      fieldOptions = {
        ...field,
        inputOptions: {
          onChange: e => {
            const newValue = e.target.value;
            if (field.type === "checkbox") {
              if (e.target.checked) {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    [field.id]: state.form[field.id]
                      ? [...state.form[field.id], newValue]
                      : [newValue]
                  }
                }));
              } else {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    [field.id]: state.form[field.id].filter(v => v !== newValue)
                  }
                }));
              }
            } else if (field.type === "radio") {
              this.setState(state => ({
                form: {
                  ...state.form,
                  [field.id]: newValue
                }
              }));
            }
          }
        }
      };
    } else {
      fieldOptions = field;
    }

    switch (field.type) {
      case "hidden":
      case "email":
      case "tel":
      case "text":
      case "date":
        return (
          <TextField
            {...fieldOptions}
            inputClass={inputClass}
            error={this.getErrors(fieldOptions.id)}
            key={fieldOptions.id}
          />
        );
      case "select":
        return (
          <SelectField
            {...fieldOptions}
            inputClass={inputClass}
            error={this.getErrors(fieldOptions.id)}
            key={fieldOptions.id}
          />
        );
      case "multicheck":
        return null; // <MultiCheck {...field} />
      case "checkbox":
      case "radio":
        return (
          <RadioField
            {...fieldOptions}
            inputClass={inputClass}
            error={this.getErrors(fieldOptions.id)}
            key={fieldOptions.id}
          />
        );
      case "section":
        return (
          <div className="col-md-12">
            <Heading level={6} mt="small" mb="small" color={"blue"}>
              {fieldOptions.label}
            </Heading>
          </div>
        );
      case "copy":
        return (
          <div className="col-md-12">
            <Copy size="small">{fieldOptions.label}</Copy>
          </div>
        );
      case "alertSuccess":
        return (
          <div className="col-md-12">
            <Alert status="success">{fieldOptions.label}</Alert>
          </div>
        );
      case "page": {
        const { page: p } = this.props;

        if (!p) return null;

        if (field.id === "title") {
          return (
            <div className="col-md-12">
              <Heading level={6} mt="small" mb="small" color={"blue"}>
                {p.title.rendered}
              </Heading>
            </div>
          );
        } else if (field.id === "content") {
          return (
            <div className="col-md-12">
              <Copy size="small" html={p.content.rendered} />
            </div>
          );
        } else {
          const arr = field.id.split(".");

          if (arr.length > 0 && ["content", "title"].includes(arr[0])) {
            const component = arr.shift();

            const { content } = mapper(p, {
              content: arr
            });

            if (component === "content" && content) {
              return (
                <div className="col-md-12">
                  <Copy size="small" html={content} />
                </div>
              );
            } else if (component === "title" && content) {
              return (
                <div className="col-md-12">
                  <Heading level={6} mt="small" mb="small" color={"blue"}>
                    {content}
                  </Heading>
                </div>
              );
            }
          }

          return null;
        }
      }
      case "textarea":
        return <TextAreaField {...fieldOptions} />;
      case "break":
        return <div className="col-md-12" />;
      case "multi":
        return <MultiInput {...field} />;
      case "group":
        return this.getGroupField(field);
      case "location":
        return (
          <GoogleContainer
            render={google => (
              <div className="col-md-12">
                {google ? (
                  <SearchBar
                    {...field}
                    addressError={this.getErrors(fieldOptions.id)}
                    input={this.state.input}
                    onChange={address => this.setState({ input: address })}
                    onSelect={(address, { lat, lng }) => {
                      this.setState({
                        address,
                        input: address.formattedAddressString,
                        currentLocation: { lat, lng }
                      });
                    }}
                  />
                ) : null}
              </div>
            )}
          />
        );
      case "async|select":
      case "async|radio": {
        return (
          <QueryContainer
            query={field.query}
            render={items => {
              const type = field.type.split("|");
              let currentOptions = field.options || [];
              console.log(type);
              return this.getField({
                ...field,
                type: type[1],
                options: [...items, ...currentOptions]
              });
            }}
          />
        );
      }
      default:
        return <TextField {...fieldOptions} />;
    }
  }

  getFieldConditional = field => {
    const { form } = this.props;

    if (field.conditional) {
      const math_it_up = {
        is: (x, y) => {
          return x === y;
        },
        isnot: (x, y) => {
          return x !== y;
        },
        includes: (x, y) => {
          return x.includes(y);
        },
        interect: (x, y) => {
          var z = x.filter(function(val) {
            return y.split(",").indexOf(val) !== -1;
          });
          return z.length > 0;
        }
      };

      let currentValues = {};

      if (form.stateManage) {
        currentValues = this.state.form;
      } else {
        const { values = {} } = this.props;
        currentValues = values;
      }

      const {
        conditionalLogic: { actionType, logic }
      } = field;

      let a;

      if (typeof currentValues[logic.field] === "undefined") {
        a = false;
      } else {
        a = math_it_up[logic.match](currentValues[logic.field], logic.value);
      }

      if (a) {
        if (actionType === "show") {
          return true;
        } else {
          return false;
        }
      } else {
        if (actionType === "show") {
          return false;
        } else {
          return true;
        }
      }
    }

    return true;
  };

  createField(field) {
    const shouldShow = this.getFieldConditional(field);
    if (shouldShow) return this.getField(field);
    return null;
  }

  showField(field) {
    const shouldShow = this.getFieldConditional(field);
    if (shouldShow) {
      return true;
    }

    return false;
  }

  getUrlParams(search) {
    let hashes = search.slice(search.indexOf("?") + 1).split("&");
    let params = {};
    hashes.forEach(hash => {
      let [key, val] = hash.split("=");
      params[key] = decodeURIComponent(val);
    });

    return params;
  }

  onSubmit = async e => {
    e.preventDefault();

    const formValues = serializeForm(e.target, { hash: true });
    const values = this.formatValues(formValues, this.state);

    const errors = this.validate(values);

    this.setState({
      errors: [],
      sent: false,
      loading: true
    });

    if (Object.keys(errors).length) {
      this.setState({
        errors,
        loading: false,
        sent: false
      });
      return;
    }

    if (this.props.onSubmit) {
      const result = await this.props.onSubmit(values);

      this.setState({
        ...result,
        loading: false
      });
    }
  };

  render() {
    const { form, buttonClass } = this.props;
    const { errors, loading, sent, error, message = "" } = this.state;

    return (
      <form onSubmit={this.onSubmit} ref={node => (this.form = node)}>
        {sent ? (
          <Alert close={false} status="success">
            {message}
          </Alert>
        ) : null}
        {error ? (
          <Alert close={false} status="danger">
            {error}
          </Alert>
        ) : null}
        <div className="row">
          {form.fields.map(field => (
            <React.Fragment key={field.id}>
              {this.createField(field)}
            </React.Fragment>
          ))}

          <div className={buttonClass}>
            <ButtonTest
              color="green"
              type="submit"
              full
              mb="small"
              disabled={loading}
            >
              {loading ? "Loading..." : form.buttonLabel}
            </ButtonTest>
            {Object.keys(errors).length ? (
              <Spacing top size="space2">
                <Alert close={false} status="danger">
                  There is a problem with your submission. Please check that you
                  have correctly filled in your information and completed all
                  required fields.{" "}
                </Alert>
              </Spacing>
            ) : null}
            {this.props.renderOption ? this.props.renderOption() : null}
          </div>
        </div>
      </form>
    );
  }
}

FormBuilderContainer.defaultProps = {
  form: null,
  onSubmit: null,
  buttonClass: "col-md-12"
};

export default FormBuilderContainer;
