import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import {
  Copy,
  Heading,
  Icon,
  MoreLink,
  ButtonTest,
  variables,
  Border
} from "@website2018/da-dobsonville";
import language from "../../common/language";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton
} from "react-share";
import { PageContainer } from "../../containers";
import { getShareProperties } from "../../common/utils";
import { Spacing } from "../../components";
import Google from "../../containers/Map/Google";
import GoogleMap from "../../containers/Map/GoogleMapContainer";

class ConfirmContainer extends React.Component {
  getRegStatus() {
    const { spacing } = variables;
    const {
      getVoterInfo: { voter, livingVD }
    } = this.props;

    if (voter.registered) {
      if (!livingVD) {
        return <Copy>{language.vdNotFound}</Copy>;
      }

      if (voter.correct) {
        return <Copy>{language.registered}</Copy>;
      }

      return (
        <React.Fragment>
          <Copy html={language.registerAt} />
          <div
            className="alert alert-danger py-4 px-4"
            style={{ width: "100%" }}
          >
            <h6>IMPORTANT</h6>
            <p>
              Until completing the{" "}
              <strong>IEC Online Voter Registration</strong>, later in this
              process, and until you receive written confirmation of your
              registration being successful, from the IEC (SMS or Email) you
              will <strong>NOT</strong> yet be correctly Registered to vote.
            </p>
            {/* <p>
              The IEC SMS will include words stating “The holder of ID number
              XXXXXXXXXXXXX is registered on DD/MM/YYYY in VD XXXXXXXX at Voting
              Station: ABCDEFG.” Starting this process, but not completing it,
              and not receiving the IEC written confirmation, will NOT lead to
              Voter Registration.
            </p> */}
          </div>
          <Border />
          <div>
            <Heading size="small" color="blue">
              Ready to begin the IEC Process?
            </Heading>
            <Spacing top bottom size={spacing.space2}>
              <a
                href="https://registertovote.elections.org.za/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonTest color="green" size="small" mb="small">
                  Click Here
                </ButtonTest>
              </a>
            </Spacing>
            <div style={{ width: "100%" }}>
              <p>
                <strong>IMPORTANT:</strong> The picture of your ID document must
                be uploaded from your device’s camera roll, album or gallery, so
                please take the picture before you click the link below,
                ensuring that your ID barcode is clear in the picture.
              </p>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Copy html={language.notRegistered} />
        <div className="alert alert-danger py-4 px-4" style={{ width: "100%" }}>
          <h6>IMPORTANT</h6>
          <p>
            Until completing the <strong>IEC Online Voter Registration</strong>,
            later in this process, and until you receive written confirmation of
            your registration being successful, from the IEC (SMS or Email) you
            will <strong>NOT</strong> yet be correctly Registered to vote.
          </p>
          {/* <p>
            The IEC SMS will include words stating “The holder of ID number
            XXXXXXXXXXXXX is registered on DD/MM/YYYY in VD XXXXXXXX at Voting
            Station: ABCDEFG.” Starting this process, but not completing it, and
            not receiving the IEC written confirmation, will NOT lead to Voter
            Registration.
          </p> */}
        </div>
        <Border />
        <div className="mt-4">
          <Heading size="small" color="blue">
            Ready to begin the IEC Process?
          </Heading>
          <Spacing top bottom size={spacing.space2}>
            <a
              href="https://registertovote.elections.org.za/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ButtonTest color="green" size="small" mb="small">
                Click Here
              </ButtonTest>
            </a>
          </Spacing>
          <p>
            <strong>IMPORTANT:</strong>The picture of your ID document must be
            uploaded from your device’s camera roll, album or gallery, so please
            take the picture before you click the link below, ensuring that your
            ID barcode is clear in the picture.
          </p>
        </div>
      </React.Fragment>
    );
  }

  getRegAddress() {
    const {
      getVoterInfo: { voter }
    } = this.props;

    if (voter.iecAddressStatus) {
      return null;
    }

    return (
      <div>
        <Heading size="small" color="blue">
          {language.regAddressHeading}
        </Heading>
        <Copy color={"red"} html={language.regAddressCopy} />
      </div>
    );
  }

  getVotingStation() {
    const {
      getVoterInfo: { livingVD }
    } = this.props;

    if (livingVD) {
      const { vd_name, muni_shortname, ward_num, votingstations } = livingVD;
      const vs = votingstations[0];

      return (
        <div>
          <Copy mb="none">{vd_name}</Copy>
          <Copy mb="none">{vs.vs_street}</Copy>
          <Copy mb="none">
            Ward {ward_num} in {muni_shortname}.
          </Copy>
          <Copy>
            <MoreLink
              href={`https://www.google.com/maps/dir/${vs.vs_latitude},${
                vs.vs_longitude
              }`}
              target={"_blank"}
            >
              Get Directions
            </MoreLink>
          </Copy>
        </div>
      );
    }

    return null;
  }

  render() {
    const { colors, spacing } = variables;

    const {
      getVoterInfo: { livingVD }
    } = this.props;

    const votingStation = this.getVotingStation();

    return (
      <Spacing top bottom size={spacing.space4}>
        <div className="container">
          <PageContainer
            key={"page-data"}
            render={p => (
              <div className="row">
                {votingStation ? (
                  <div className="col-lg-4 mb-4">
                    <Google
                      render={google => {
                        if (google)
                          return (
                            <GoogleMap
                              mapStyle={"new-map"}
                              google={google}
                              livingVD={livingVD}
                              loadVoter
                            />
                          );
                        return null;
                      }}
                    />
                  </div>
                ) : null}
                <div className="col-lg-8">
                  {votingStation ? (
                    <React.Fragment>
                      <Heading size="small" color="blue">
                        {language.myVotingStation}
                      </Heading>

                      {this.getVotingStation()}
                    </React.Fragment>
                  ) : null}

                  <Heading size="small" color="blue">
                    {language.regStatus}
                  </Heading>
                  {this.getRegStatus()}

                  <Heading size="small" color="blue">
                    {language.share}
                  </Heading>
                  <Copy mb="small" size="small">
                    {language.shareCopy}
                  </Copy>
                  <Copy>
                    <FacebookShareButton {...getShareProperties("fb", p)}>
                      <Icon name="facebook" color={colors.socialFacebook} />
                    </FacebookShareButton>
                    <WhatsappShareButton {...getShareProperties("wa", p)}>
                      <Icon name="whatsapp" color={colors.socialWhatsApp} />
                    </WhatsappShareButton>
                    <TwitterShareButton {...getShareProperties("tw", p)}>
                      <Icon name="twitter" color={colors.socialTwitter} />
                    </TwitterShareButton>
                    <EmailShareButton {...getShareProperties("email", p)}>
                      <Icon name="envelope" color={colors.brandBlue} />
                    </EmailShareButton>
                  </Copy>
                </div>
              </div>
            )}
          />
        </div>
      </Spacing>
    );
  }
}

const GET_VOTER_INFO = gql`
  query getVoterInfo($id: String!) {
    getVoterInfo(id: $id) {
      voter {
        correct
        registered
        iecAddressStatus
      }
      livingVD {
        vd_name
        vd_code
        muni_shortname
        ward_num
        geometry {
          geometry
          type
        }
        votingstations {
          vs_name
          vs_street
          vs_latitude
          vs_longitude
        }
      }
    }
  }
`;

const withVoter = graphql(GET_VOTER_INFO, {
  options: props => {
    return {
      variables: {
        id: props.match.params.id
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

export default withVoter(ConfirmContainer);
