import React, { Component, Fragment } from "react";
import { Copy, Hero, SectionFull } from "@website2018/da-dobsonville";
import { Helmet } from "react-helmet";

import { PageContainer } from "../../containers";
import { ListItem, Spacing } from "../../components";
import { CanvassContainer } from "../Canvass/CanvassContainer";

class LandingContainer extends Component {
  render() {
    const referralCode = this.props.match.params.referralCode || null;
    let path;
    if (typeof window !== "undefined") {
      path = window.location.href;
    }
    return (
      <PageContainer
        render={p => (
          <Fragment>
            <Helmet
              defaultTitle={p.seo.title !== "" ? p.seo.title : p.title.rendered}
              meta={[
                {
                  name: "description",
                  content:
                    p.seo.metadesc !== "" ? p.seo.metadesc : p.excerpt.rendered
                },
                {
                  name: "og:title",
                  content:
                    p.seo["opengraph-title"] !== ""
                      ? p.seo["opengraph-title"]
                      : p.title.rendered
                },
                {
                  name: "og:description",
                  content:
                    p.seo["opengraph-description"] !== ""
                      ? p.seo["opengraph-description"]
                      : p.excerpt.rendered
                },
                { name: "og:url", content: `` },
                { name: "og:site_name", content: `Democratic Alliance` },
                { name: "og:type", content: `business` },
                { name: "og:image", content: p.seo["opengraph-image"] || null },
                { name: "fb:app_id", content: "1262049647255981" },
                { name: "twitter:card", content: "summary_large_image" },
                { name: "twitter:site", content: "@our_da" },
                {
                  name: "twitter:title",
                  content:
                    p.seo["twitter-title"] !== ""
                      ? p.seo["twitter-title"]
                      : p.title.rendered
                },
                {
                  name: "twitter:description",
                  content:
                    p.seo["twitter-description"] !== ""
                      ? p.seo["twitter-description”"]
                      : p.excerpt.rendered
                },
                {
                  name: "twitter:image:src",
                  content:
                    p.seo["twitter-image"] !== ""
                      ? p.seo["twitter-image"]
                      : null
                }
              ]}
              h
              tmlAttributes={{ lang: "en" }}
            />

            <Hero
              imageUrl={null}
              title={p.hero.headline || p.title.rendered}
              mb={"none"}
              small
            />
            {p.content.rendered && (
              <SectionFull>
                <div className="col-lg-4 mb-4 ">
                  <Spacing
                    padding
                    top
                    bottom
                    left
                    right
                    size="space3"
                    style={{ background: "#eee" }}
                  >
                    <CanvassContainer
                      page={p}
                      referralCode={referralCode}
                      path={path}
                    />
                  </Spacing>
                </div>
                <div className={"col-lg-7 mb-4 ml-auto entry"}>
                  <Copy html={p.content.rendered} />
                </div>
              </SectionFull>
            )}
            <SectionFull title={p.faqTitle} mt="medium" mb="medium">
              <div className="col-lg-8">
                {p.faq.map((f, index) => (
                  <ListItem key={`${index}-faq`} title={f.question} collapsed>
                    <Spacing top bottom size={"space2"}>
                      <Copy html={f.answer} />
                    </Spacing>
                  </ListItem>
                ))}
              </div>
            </SectionFull>
          </Fragment>
        )}
      />
    );
  }
}

export default LandingContainer;
