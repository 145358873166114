import React from "react";
import { Logo, ButtonTest } from "@website2018/da-dobsonville";

const Header = (props, context) => {
  return (
    <div
      style={{
        backgroundColor: "#155FA2",
        paddingTop: "16px",
        paddingBottom: "16px",
        width: "100%"
      }}
    >
      <div className="container">
        <div className="row">
          <div className={"col-2"}>
            <Logo division={null} size="80px" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
